import { useMemo } from 'react'
import { Regen721Engine } from '@space3/web3'

import { useEthersSigner } from '../ether/useEthersSigner'

export const useWriteRegen721EngineContract = () => {
  const signer = useEthersSigner()

  const contract = useMemo(
    () =>
      Regen721Engine.connect(
        process.env.NEXT_PUBLIC_REGEN_721_CONTRACT_ADDRESS as string,
        signer,
      ),
    [signer],
  )

  return {
    contract,
  }
}
