import { useQuery } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'

import { useReadRegen721EngineContract } from './useReadRegen721EngineContract'

import { QUERY_KEY } from '@/constants'
import { ONE_MINUTE } from '@/constants/date-time.constant'

export const useSeasonSetting = (seasonId: BigNumber) => {
  const { contract } = useReadRegen721EngineContract()

  return useQuery({
    queryKey: [QUERY_KEY.GET_SEASON_SETTING, seasonId],
    queryFn: async () => {
      const { burnToken, mintToken } = await contract.burnMintSettings(
        seasonId.str(),
      )

      return { burnToken, mintToken }
    },
    enabled: !!seasonId,
    staleTime: 10 * ONE_MINUTE,
  })
}
