import { useMemo } from 'react'
import { useAccount } from 'wagmi'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'

import { Col, Row, Typography } from 'antd'

import RevealNftProvider from './RevealNft.context'
import Countdown from '@/components/countdown'
import BurnRule from './burn-rule'
import NftConvert from './nft-convert'
import NftDetails from './nft-details'
import RevealPopup from '../reveal-popup'
import RevealingPopup from '../revealing-popup'

import { useAllSeasonRounds } from '@/hooks/regen721/useAllSeasonRounds'
import { useSeasonMetadata } from '@/hooks/regen721/useSeasonMetadata'
import { useRoundWhitelistByUserAddress } from '@/hooks/regen721/useRoundWhitelistByUserAddress'
import { useBalanceOfAddress } from '@/hooks/genesis/useBalanceOfAddress'

import './index.scss'

type BurnNftProps = {
  seasonId: BigNumber
}

function BurnNft({ seasonId }: BurnNftProps) {
  const { address } = useAccount()
  const formatAddress = address ? `${address}` : ''
  const { data: balanceOf } = useBalanceOfAddress(formatAddress)

  const { data: seasonMetadata } = useSeasonMetadata(seasonId)

  const { data: seasonRounds } = useAllSeasonRounds(seasonId)

  const startDateSeason =
    seasonRounds && seasonRounds.length > 0
      ? dayjs.unix(seasonRounds[0].startTime.toNumber())
      : dayjs(null)
  const endDateSeason =
    seasonRounds && seasonRounds.length > 0
      ? dayjs.unix(seasonRounds[seasonRounds.length - 1].endTime.toNumber())
      : dayjs(null)

  const currentTime = BigNumber(dayjs().unix())
  // Find the current round index based on the current time
  const roundIndex = useMemo(() => {
    if (!seasonRounds || seasonRounds.length === 0) return -1

    // Get the current round or the last round if none are active
    const activeRoundIndex = seasonRounds.findIndex((round) => {
      return (
        currentTime.isGreaterThanOrEqualTo(round.startTime) &&
        currentTime.isLessThanOrEqualTo(round.endTime)
      )
    })

    // If a valid round is found, return its index
    if (activeRoundIndex !== -1) return activeRoundIndex

    // If not found, check if the current time has passed all rounds
    const allRoundsEnded = seasonRounds.every((round) => {
      return currentTime.isGreaterThan(round.endTime)
    })

    // If all rounds have ended, select the last round
    if (allRoundsEnded) return seasonRounds.length - 1

    // If the time has not yet reached the start of any round, select the upcoming round (the one with the closest startTime to the current time)
    const upcomingRoundIndex = seasonRounds.findIndex((round) => {
      return currentTime.isLessThan(round.startTime)
    })

    return upcomingRoundIndex !== -1 ? upcomingRoundIndex : -1
  }, [seasonRounds, currentTime])

  const round =
    roundIndex !== -1
      ? seasonRounds[roundIndex]
      : seasonRounds[seasonRounds.length - 1]

  const title = useMemo(
    () => seasonMetadata?.rounds[roundIndex]?.title ?? 'Unknown round',
    [seasonMetadata, roundIndex],
  )

  const { eligible } = useRoundWhitelistByUserAddress(
    seasonId,
    BigNumber(roundIndex),
    address,
  )

  return (
    <RevealNftProvider>
      <Row
        className="burn-nft-container h-[617px] mobile:h-[475px] overflow-y-hidden"
        gutter={[0, { xs: 20, sm: 20, lg: 24 }]}
      >
        <Col span={24} className="burn-nft--header">
          <Row justify="space-evenly" gutter={[0, 24]}>
            <Col lg={12} sm={24} xs={24} className="flex items-center gap-3">
              <Typography.Text type="success" className="burn-nft-title">
                {title}
              </Typography.Text>

              {eligible ? (
                <Typography.Text className="burn-nft-title--warning">
                  ELIGIBLE
                </Typography.Text>
              ) : null}
            </Col>
            <Col lg={12} sm={24} xs={24}>
              <Countdown
                startedAt={dayjs.unix(round?.startTime.toNumber())}
                endedAt={dayjs.unix(round?.endTime.toNumber())}
              />
            </Col>
          </Row>
        </Col>

        <Col
          span={24}
          className="burn-nft--content max-h-[522px] scrollbar px-4 mobile:px-2"
        >
          <Row gutter={[0, { xs: 20, sm: 20, lg: 32 }]}>
            <Col span={24} className="flex justify-center">
              <div className="live-item">
                <span className="icon-circle" />
                <Typography.Text>LIVE</Typography.Text>
              </div>
            </Col>

            <Col span={24}>
              <Row gutter={[0, 12]} align="middle">
                <Col span={24}>
                  <NftConvert
                    isBaseNftExist={Boolean(balanceOf && balanceOf > 0)}
                  />
                </Col>

                <Col span={24}>
                  {/* TODO: update description later when BA has a final description */}
                  <BurnRule desc="Art is the most addictive meme! OOKAMI is based is the exclusive key to obtaining the $OOKAMI. #DN404 Art by Gharliera Art is the key to Gharliera" />
                </Col>
              </Row>
            </Col>
          </Row>

          <NftDetails
            seasonId={seasonId}
            startTime={startDateSeason}
            endTime={endDateSeason}
          />
        </Col>
      </Row>
      <RevealPopup />
      <RevealingPopup />
    </RevealNftProvider>
  )
}

export default BurnNft
