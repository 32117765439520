import { useEffect, useState } from 'react'

import { Avatar } from 'antd'

import LoadingMainIcon from '@/assets/images/icon/loading-main.gif'

import './first-page-loading.scss'

function FirstPageLoading({ isLoading }: { isLoading: boolean }) {
  const [showLoading, setShowLoading] = useState(true)

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setShowLoading(false)
      }, 500)
    }
  }, [isLoading])

  if (!showLoading) return null

  return (
    <div
      className="first-page-loading"
      style={{
        background: showLoading ? '#000' : '#141516bb',
        opacity: isLoading ? 1 : 0,
      }}
    >
      <Avatar src={LoadingMainIcon.src} size={100} alt="main-loading" />
    </div>
  )
}

export default FirstPageLoading
