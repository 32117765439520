import BigNumber from 'bignumber.js'
import { useQuery } from '@tanstack/react-query'

import { useReadRegen721EngineContract } from './useReadRegen721EngineContract'

import { QUERY_KEY } from '@/constants'
import { ONE_MINUTE } from '@/constants/date-time.constant'

export const useNextSeasonId = () => {
  const { contract } = useReadRegen721EngineContract()
  return useQuery({
    queryKey: [QUERY_KEY.GET_NEXT_SEASON_ID],
    queryFn: async () => {
      const nextSeasonId = await contract.nextSeasonId()
      return BigNumber(nextSeasonId.toString())
    },
    staleTime: 10 * ONE_MINUTE,
  })
}
