import { nftApi } from '../base-axios'

import { GetEncryptionKeyParams } from '@/types/nft-service/metadata.type'
import { GetNftParams, INft } from '@/types/nft-service/nft.type'

const NFT_PATH = '/nft'
const METADATA_PATH = '/metadata'

class NftService {
  async getNft(params: GetNftParams) {
    const { data } = await nftApi.get<INft>(NFT_PATH, {
      params: params,
    })
    return data
  }

  async getEncryptionKey(params: GetEncryptionKeyParams) {
    const { data } = await nftApi.get<string>(
      `${METADATA_PATH}/encryption-key`,
      {
        params: params,
      },
    )
    return data + ''
  }
}

export const nftService = new NftService()
