import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useAccount, useBalance } from 'wagmi'
import BigNumber from 'bignumber.js'
import { StandardMerkleTree } from '@openzeppelin/merkle-tree'
import { ContractTransactionResponse, MaxUint256 } from 'ethers'
import { ERC20 } from '@space3/web3'
import { HexString } from '@openzeppelin/merkle-tree/dist/bytes'

import { Flex } from 'antd'

import { notify } from '@/providers/notify.provider'
import eventEmitter from '@/providers/event.provider'

import BurnAmountAndPrice from './BurnAmountAndPrice'
import BurnBtn from './BurnBtn'
import BurningPopup from '../burning-popup'

import { useCurrentSeason } from '@/hooks/system/useCurrentSeason'
import { useAllSeasonRounds } from '@/hooks/regen721/useAllSeasonRounds'
import { useRoundWhitelistByUserAddress } from '@/hooks/regen721/useRoundWhitelistByUserAddress'
import { useTotalBurnedByUserAddress } from '@/hooks/regen721/useTotalBurnedByUserAddress'
import { useBurnTokens } from '@/hooks/regen721/useBurnTokens'
import { useSeason } from '@/hooks/regen721/useSeason'
import { useTokenIdsOwnedByAddress } from '@/hooks/genesis/useTokenIdsOwnedByAddress'
import { useReadRegen721EngineContract } from '@/hooks/regen721/useReadRegen721EngineContract'
import { useReadGenesisContract } from '@/hooks/genesis/useReadGenesisContract'
import { useSetApprovedForAll } from '@/hooks/genesis/useSetApprovedForAll'
import { useEthersSigner } from '@/hooks/ether/useEthersSigner'

import { NATIVE_TOKEN, SystemEvent } from '@/constants'

const Burn = () => {
  const signer = useEthersSigner()
  const { address } = useAccount()
  const { contract: regenContract } = useReadRegen721EngineContract()
  const { contract: genesisContract } = useReadGenesisContract()
  const { data: userNativeBalance } = useBalance({ address: address })
  const seasonId = useCurrentSeason()
  const [selectedAmount, setSelectedAmount] = useState(0)
  const { refetch: refetchSeason } = useSeason(seasonId!)
  const { data: rounds, refetch: refetchSeasonRound } = useAllSeasonRounds(
    seasonId!,
  )
  const [burning, setBurning] = useState(false)
  const [isBurningModalOpen, setIsBurningModalOpen] = useState(false)
  const [progressBarPercent, setProgressBarPercent] = useState(0)

  const [activeRound, roundIndex] = useMemo(() => {
    if (!rounds) return [undefined, BigNumber(-1)]

    const roundIndex = rounds.findIndex(({ startTime, endTime }) => {
      const startedDate = dayjs(startTime.multipliedBy(1000).toNumber())
      const endedDate = dayjs(endTime.multipliedBy(1000).toNumber())
      return (
        dayjs().utc().isAfter(startedDate) && dayjs().utc().isBefore(endedDate)
      )
    })

    return [rounds[roundIndex], BigNumber(roundIndex)]
  }, [rounds])

  const {
    data: [, whitelistAmount],
    roundWhitelist,
  } = useRoundWhitelistByUserAddress(seasonId!, roundIndex, address)

  const { data: totalBurned, refetch: refetchTotalBurned } =
    useTotalBurnedByUserAddress(seasonId!, roundIndex, address!)
  const { data: genesisTokenIdsOwnedByUser, refetch: refetchTokenIdsList } =
    useTokenIdsOwnedByAddress(address!)

  const disabledBurn = useMemo(() => {
    if (genesisTokenIdsOwnedByUser.length < 1) return true
    if (selectedAmount < 1) return true
    if (!activeRound) return true
    if (whitelistAmount < 1 && roundWhitelist && roundWhitelist.length > 0)
      return true

    return false
  }, [
    genesisTokenIdsOwnedByUser,
    selectedAmount,
    activeRound,
    whitelistAmount,
    roundWhitelist,
  ])

  const burnBtnTooltip = useMemo(() => {
    if (genesisTokenIdsOwnedByUser.length < 1)
      return 'You have no Genesis NFT in your wallet'
  }, [genesisTokenIdsOwnedByUser])

  const { mutateAsync: burn } = useBurnTokens()
  const { mutateAsync: setApprovedForAll } = useSetApprovedForAll()

  const onBurn = async () => {
    try {
      if (!genesisContract || !seasonId) return
      if (genesisTokenIdsOwnedByUser.length < selectedAmount) {
        throw new Error("Oops! You don't have enough Genesis NFT to burn!")
      }

      setBurning(true)

      // has prevent by disabled props
      if (selectedAmount < 1 || !activeRound || !address) {
        return
      }

      if (!roundWhitelist) {
        throw new Error('Missing round metadata!')
      }

      // Check approve to burn
      const isApproveForAll = await genesisContract.isApprovedForAll(
        address,
        await regenContract.getAddress(),
      )
      let tx: ContractTransactionResponse | undefined
      const regenAddress = await regenContract.getAddress()
      if (!isApproveForAll) {
        tx = await setApprovedForAll({
          spender: regenAddress,
        })

        if (!tx) throw new Error('Something went wrong!')
        await tx.wait()
      }

      // Check price
      let requiredPrice = BigNumber(0)
      if (!activeRound.pricePerToken.eq(0)) {
        requiredPrice = activeRound.pricePerToken.multipliedBy(selectedAmount)
        if (activeRound.currency === NATIVE_TOKEN) {
          if (requiredPrice.gt(userNativeBalance?.value.toString() ?? 0)) {
            throw new Error(
              "Oops! You don't have enough tokens. Please deposit more to proceed.",
            )
          }
        } else {
          const erc20Contract = ERC20.connect(activeRound.currency, signer)
          const userErc20Balance = await erc20Contract.balanceOf(address)
          if (requiredPrice.gt(userErc20Balance.toString())) {
            throw new Error(
              "Oops! You don't have enough tokens. Please deposit more to proceed.",
            )
          }

          const userErc20Allowance = await erc20Contract.allowance(
            address,
            regenAddress,
          )
          if (requiredPrice.gt(userErc20Allowance.toString())) {
            tx = await erc20Contract.approve(
              regenAddress,
              requiredPrice.bigInt(),
            )
            await tx.wait()
          }
          requiredPrice = BigNumber(0)
        }
      }

      // Build Merkle tree
      let proofs: HexString[] = []
      if (roundWhitelist.length > 0) {
        const tree = StandardMerkleTree.of(roundWhitelist, [
          'address',
          'uint256',
        ])
        proofs = tree.getProof([address, whitelistAmount])
      }

      tx = await burn({
        seasonId,
        roundIndex,
        total: BigNumber(whitelistAmount || MaxUint256.toString()),
        burnTokenIds: genesisTokenIdsOwnedByUser.slice(0, selectedAmount),
        proofs,
        price: requiredPrice,
      })

      setIsBurningModalOpen(true)
      await tx.wait()
      setProgressBarPercent(100)

      await refetchTokenIdsList()
      await refetchSeason()
      await refetchSeasonRound()
      await refetchTotalBurned()
      setSelectedAmount(0)
      eventEmitter.emit(SystemEvent.BURN_NFTS)

      notify.success({ message: 'Burn successfully' })
    } catch (error: any) {
      notify.error({
        message:
          error?.shortMessage ?? error?.message ?? 'Something went wrong!',
      })
    } finally {
      setBurning(false)
      setIsBurningModalOpen(false)
      setProgressBarPercent(0)
    }
  }

  useEffect(() => {
    if (!isBurningModalOpen) return

    const interval = setInterval(() => {
      setProgressBarPercent((prev) => {
        const newPercent = prev + Math.random() * 1 + 1
        if (newPercent >= 99) return prev
        return Math.round(newPercent)
      })
    }, 100)

    return () => clearInterval(interval)
  }, [isBurningModalOpen])

  return (
    <Flex vertical gap={12}>
      <BurnAmountAndPrice
        activeRound={activeRound}
        max={
          roundWhitelist?.length === 0
            ? BigNumber(genesisTokenIdsOwnedByUser.length)
            : BigNumber(whitelistAmount).minus(totalBurned)
        }
        selectedAmount={selectedAmount}
        setSelectedAmount={(amount) => {
          if (burning) return
          setSelectedAmount(amount)
        }}
      />
      <BurnBtn
        onBurn={onBurn}
        loading={burning}
        disable={disabledBurn || burning}
        tooltip={burnBtnTooltip}
      />
      <BurningPopup
        open={isBurningModalOpen}
        progressBarPercent={progressBarPercent}
      />
    </Flex>
  )
}

export default Burn
