import BigNumber from 'bignumber.js'
import { useRouter } from 'next/router'
import { useNextSeasonId } from '../regen721/useNextSeasonId'

export const useCurrentSeason = () => {
  const router = useRouter()
  const { season } = router.query
  const { data: nextSeasonId } = useNextSeasonId()

  if (season) return BigNumber(season as string)

  if (nextSeasonId) return nextSeasonId.minus(1)
}
