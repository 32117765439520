import clsx from 'clsx'

import { Button } from 'antd'

import { DownArrowIcon } from '@/assets/images/icon'

import { CustomArrowProps } from '@/types/component.type'

import './index.scss'

type ArrowButtonProps = {
  isPrev?: boolean
} & CustomArrowProps

const ArrowButton = ({ isPrev, className, ...rest }: ArrowButtonProps) => {
  return (
    <Button type="primary" {...rest} className={clsx(className, 'btn-arrow')}>
      <DownArrowIcon
        style={{
          transform: isPrev ? 'rotate(90deg)' : 'rotate(-90deg)',
        }}
      />
    </Button>
  )
}

export default ArrowButton
