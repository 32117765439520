import BigNumber from 'bignumber.js'
import { useQuery } from '@tanstack/react-query'

import { useReadEliteContract } from './useReadEliteContract'

import { QUERY_KEY } from '@/constants'

export const useTokenUri = (tokenId: BigNumber) => {
  const { contract } = useReadEliteContract()
  return useQuery({
    queryKey: [QUERY_KEY.GET_TOKEN_URI, tokenId.str()],
    queryFn: () => contract?.tokenURI(tokenId.bigInt()),
    enabled: !!contract,
  })
}
