import Papaparse, { ParseResult } from 'papaparse'

import { awsS3Api } from '../base-axios'

import {
  RoundWhitelistCSVFormat,
  SeasonMetadata,
} from '@/types/regen-721-engine.type'

class AwsS3Service {
  async getSeasonMetadata(seasonId: string) {
    const { data } = await awsS3Api.get<SeasonMetadata>(
      `/season-${seasonId}/metadata.json`,
    )
    return data
  }

  async getRoundWhitelist(seasonId: string, roundIndex: string) {
    try {
      const result: ParseResult<RoundWhitelistCSVFormat> = await new Promise(
        (resolve, reject) => {
          Papaparse.parse<RoundWhitelistCSVFormat>(
            awsS3Api.getUri() +
              `/season-${seasonId}/round-${roundIndex}/whitelist.csv`,
            {
              delimiter: ',',
              newline: '\n',
              download: true,
              skipEmptyLines: true,
              complete(results) {
                resolve(results)
              },
              transform(value, field) {
                if (field === 1) return Number(value)

                return value
              },
              error(_error) {
                reject(_error)
              },
            },
          )
        },
      )

      if (result.errors.length > 0) {
        throw new Error('Something went wrong')
      }

      // Remove header
      return result.data.slice(1)
    } catch (error: any) {
      if (error?.message === 'Forbidden') {
        return []
      }

      throw error
    }
  }
}

export const awsS3Service = new AwsS3Service()
