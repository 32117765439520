import { useMemo } from 'react'

import { Flex, Progress, Typography } from 'antd'

import { useSeason } from '@/hooks/regen721/useSeason'
import { useCurrentSeason } from '@/hooks/system/useCurrentSeason'

function BurnTotal() {
  const seasonId = useCurrentSeason()
  const { data: season } = useSeason(seasonId!)
  const [totalBurn, total, percent] = useMemo(() => {
    if (!season) return [0, 0, 0]

    const totalBurn = season.totalDistributed
    const total = season.maxSupply
    const percent = totalBurn.div(total).multipliedBy(100).decimalPlaces(2)
    return [totalBurn.toNumber(), total.toNumber(), percent.toNumber()]
  }, [season])

  return (
    <Flex vertical className="px-5" gap={12}>
      <Flex justify="space-between">
        <Typography.Text className="text-[16px] text-[#A3A5AE]">
          Total Burned
        </Typography.Text>

        <Typography.Text className="text-[16px]">
          {percent}
          {'% '}
          <span className="text-[#838695]">
            ({totalBurn}/{total})
          </span>
        </Typography.Text>
      </Flex>

      <Progress
        percent={percent}
        showInfo={false}
        strokeColor="rgba(255, 115, 0, 0.70)"
        rootClassName="burn-total-progress"
        className="my-[-7px]"
      />
    </Flex>
  )
}

export default BurnTotal
