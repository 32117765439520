import { useMemo } from 'react'
import BigNumber from 'bignumber.js'

import { useRoundWhitelist } from './useRoundWhitelist'

import { insensitiveEquals } from '@/helpers'
import { RoundWhitelistCSVFormat } from '@/types/regen-721-engine.type'

export const useRoundWhitelistByUserAddress = (
  seasonId: BigNumber,
  roundIndex: BigNumber,
  userAddress = '',
) => {
  const { data: roundWhitelist, ...rest } = useRoundWhitelist(
    seasonId,
    roundIndex,
  )

  const whitelist: RoundWhitelistCSVFormat = useMemo(() => {
    const defaultResult: RoundWhitelistCSVFormat = [userAddress, 0]
    if (!roundWhitelist || !userAddress) return defaultResult

    const userWhitelistInfo = roundWhitelist.find(([address]) =>
      insensitiveEquals(address, userAddress),
    )
    if (!userWhitelistInfo) return defaultResult

    return userWhitelistInfo
  }, [roundWhitelist, userAddress])

  return {
    ...rest,
    data: whitelist,
    roundWhitelist,
    eligible: whitelist[1] > 0 || roundWhitelist?.length === 0,
  }
}
