import { Col, Image, Modal, Progress, Row, Typography } from 'antd'

import LoadingMainIcon from '@/assets/images/icon/loading-main.gif'

import './index.scss'

type BurningPopupProps = {
  open: boolean
  progressBarPercent: number
}

function BurningPopup({ open, progressBarPercent }: BurningPopupProps) {
  return (
    <Modal
      footer={null}
      closeIcon={null}
      centered
      open={open}
      width={300}
      classNames={{ content: '!p-0 !bg-[unset] !shadow-none' }}
      destroyOnClose
    >
      <div className="burning-popup w-[300px] h-[300px] flex items-center px-[30px] backdrop-blur-md">
        <Row gutter={[0, 12]} justify="center" className="w-full">
          <Col span={24}>
            <Typography.Text className="block text-center font-bold font-expanded text-xl mobile:text-sm text-[#F1F2F3]">
              BURNING...
            </Typography.Text>
          </Col>
          <Col>
            <div className="h-[95px] flex items-center">
              <Image src={LoadingMainIcon.src} preview={false} alt="loading" />
            </div>
          </Col>
          <Col span={24}>
            <Progress
              percent={progressBarPercent}
              strokeColor="rgba(255, 115, 0, 0.7)"
              percentPosition={{ type: 'outer', align: 'center' }}
              rootClassName="[&_.space-3-progress-text]:order-[-1] [&_.space-3-progress-text]:!w-full [&_.space-3-progress-text]:text-right [&_.space-3-progress-text]:mb-2"
            />
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default BurningPopup
