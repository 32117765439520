import { useQuery } from '@tanstack/react-query'

import { nftService } from '@/services/nft'

import { QUERY_KEY } from '@/constants'

import { GetNftParams } from '@/types/nft-service/nft.type'

export const useNft = (params: GetNftParams) => {
  return useQuery({
    queryKey: [QUERY_KEY.GET_NFT_INFO, params],
    queryFn: () => nftService.getNft(params),
    enabled: !!params.address && !!params.chainId,
  })
}
