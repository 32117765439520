import { useCallback, useContext, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'

import { Col, Image, Modal, Progress, Row, Space, Typography } from 'antd'

import { RevealNftContext } from '../burn-nft/RevealNft.context'

import LoadingMainIcon from '@/assets/images/icon/loading-main.gif'

import './index.scss'

const RevealingPopup = () => {
  const { revealingTokenId } = useContext(RevealNftContext)

  return (
    <Modal
      footer={null}
      closeIcon={null}
      centered
      open={!!revealingTokenId}
      width={300}
      classNames={{ content: '!p-0 !bg-[unset] !shadow-none' }}
      destroyOnClose
    >
      {revealingTokenId && (
        <RevealingPopupContent revealingTokenId={revealingTokenId} />
      )}
    </Modal>
  )
}

type RevealingPopupContentProps = {
  revealingTokenId: BigNumber
}

const RevealingPopupContent = ({
  revealingTokenId,
}: RevealingPopupContentProps) => {
  /**
   * Build a progress bar
   * Split it into 3 steps:
   * - Step 1: Get encryption key ~ 20%
   * - Step 2: Send tx into pool ~ 40%
   * - Step 3: Tx was resolved ~ 100%
   */
  const [progressBarPercent, setProgressBarPercent] = useState(0)

  const {
    revealingTx,
    refetchUnrevealedTokenIds,
    setOpenRevealPopUp,
    setRevealingTokenId,
    setRecentlyRevealedTokenId,
  } = useContext(RevealNftContext)

  const waitTx = useCallback(async () => {
    if (!revealingTx) return

    await revealingTx.wait()
    setProgressBarPercent(100)
    setOpenRevealPopUp(true)
    setRevealingTokenId()
    setRecentlyRevealedTokenId(revealingTokenId)
    refetchUnrevealedTokenIds()
    // eslint-disable-next-line
  }, [revealingTx])

  useEffect(() => {
    waitTx()
  }, [waitTx])

  useEffect(() => {
    const interval = setInterval(() => {
      setProgressBarPercent((prev) => {
        const newPercent = prev + Math.random() * 1 + 1
        if (newPercent >= 99) return prev
        return Math.round(newPercent)
      })
    }, 100)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="revealing-popup w-[300px] h-[300px] flex items-center px-[30px] backdrop-blur-md">
      <Row gutter={[0, 12]} justify="center" className="w-full">
        <Col span={24}>
          <Typography.Text className="block text-center font-bold text-xl text-white">
            REAVEALING..
          </Typography.Text>
        </Col>
        <Col>
          <Space direction="vertical" align="center" size={24}>
            <Typography.Text className="text-[#A3A5AE]">
              TokenID
              <Typography.Text>
                {' '}
                {revealingTokenId?.str() ?? 'Unknown'}
              </Typography.Text>
            </Typography.Text>
            <div className="h-[95px] flex items-center">
              <Image src={LoadingMainIcon.src} preview={false} alt="loading" />
            </div>
          </Space>
        </Col>
        <Col span={24}>
          <Progress
            percent={progressBarPercent}
            strokeColor="rgba(255, 115, 0, 0.7)"
            percentPosition={{ type: 'outer', align: 'center' }}
            rootClassName="[&_.space-3-progress-text]:order-[-1] [&_.space-3-progress-text]:!w-full [&_.space-3-progress-text]:text-right [&_.space-3-progress-text]:mb-2"
          />
        </Col>
      </Row>
    </div>
  )
}

export default RevealingPopup
