import {
  type PropsWithChildren,
  type ReactNode,
  useEffect,
  useState,
} from 'react'
import dynamic from 'next/dynamic'

const MainLayoutDesktop = dynamic(
  () => import('@/layouts/main-layout/MainLayoutDesktop'),
)
const MainLayoutMobile = dynamic(
  () => import('@/layouts/main-layout/MainLayoutMobile'),
)

import FirstPageLoading from '@/components/loading/FirstPageLoading'

import useIsMobile from '@/hooks/system/useIsMobile'

type MainLayoutProps = {
  className?: string
} & PropsWithChildren

function MainLayout({ children, className }: MainLayoutProps) {
  const isMobile = useIsMobile()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isMobile !== undefined) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [isMobile])

  return (
    <div className={className}>
      {isMobile && <MainLayoutMobile>{children}</MainLayoutMobile>}
      {!isMobile && <MainLayoutDesktop>{children}</MainLayoutDesktop>}

      <FirstPageLoading isLoading={isLoading} />
    </div>
  )
}

export const getLayout = (page: ReactNode) => <MainLayout>{page}</MainLayout>

export default MainLayout
