import { Col, Row, Typography } from 'antd'

import { getLayout } from '@/layouts'
import BurnNft from '@/views/home/burn-nft'
import BurnRound from '@/views/home/burn-round'

import { useCurrentSeason } from '@/hooks/system/useCurrentSeason'

import './index.scss'

function SeasonDetail() {
  const seasonId = useCurrentSeason()

  if (!seasonId) return null

  return (
    <Row
      gutter={[0, { lg: 28, sm: 24, xs: 24 }]}
      className="home-page-container"
    >
      <Col span={24}>
        <Typography.Title level={2} className="home-page--title uppercase">
          Space3 NFT Elite
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Row gutter={[10, { lg: 0, md: 24, sm: 24, xs: 24 }]}>
          <Col xs={24} sm={24} lg={12}>
            <BurnNft seasonId={seasonId} />
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <BurnRound />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

SeasonDetail.getLayout = getLayout

export default SeasonDetail
