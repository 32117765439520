import { Fragment, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import { useAccount } from 'wagmi'
import { ERC20 } from '@space3/web3'
import clsx from 'clsx'

import { Col, Flex, Row, Typography } from 'antd'

import { LiveIcon } from '@/assets/images/icon'
import Countdown from '@/components/countdown'

import { useRoundWhitelistByUserAddress } from '@/hooks/regen721/useRoundWhitelistByUserAddress'
import { useSeasonMetadata } from '@/hooks/regen721/useSeasonMetadata'
import { useCurrentSeason } from '@/hooks/system/useCurrentSeason'
import { useEthersProvider } from '@/hooks/ether/useEthersProvider'

import { NATIVE_TOKEN } from '@/constants'

import { Round } from '@/types/regen-721-engine.type'

type BurnRoundItemProps = {
  round: Round
  roundIndex: number
}

function BurnRoundItem({ round, roundIndex }: BurnRoundItemProps) {
  const seasonId = useCurrentSeason()
  const { data: seasonMetadata } = useSeasonMetadata(seasonId!)
  const provider = useEthersProvider()
  const [decimals, setDecimals] = useState<bigint | null>(null)
  const [symbol, setSymbol] = useState<string>('')

  const { startTime, endTime } = round

  const title = useMemo(
    () => seasonMetadata?.rounds[roundIndex].title ?? 'Unknown round',
    [seasonMetadata, roundIndex],
  )
  const startedDate = useMemo(
    () => dayjs(startTime.multipliedBy(1000).toNumber()),
    [startTime],
  )
  const endedDate = useMemo(
    () => dayjs(endTime.multipliedBy(1000).toNumber()),
    [endTime],
  )
  const isLive = useMemo(
    () => dayjs().isAfter(startedDate) && dayjs().isBefore(endedDate),
    [endedDate, startedDate],
  )
  const { address } = useAccount()
  const {
    data: [, amount],
    eligible,
    roundWhitelist,
  } = useRoundWhitelistByUserAddress(seasonId!, BigNumber(roundIndex), address)

  const formattedPricePerToken = useMemo(() => {
    if (round.currency === NATIVE_TOKEN) {
      return round.pricePerToken.div(Math.pow(10, 18)).toNumber() + ' ETH'
    }

    if (!decimals) return

    return (
      round.pricePerToken.div(Math.pow(10, Number(decimals))).toNumber() +
      ' ' +
      symbol
    )
  }, [decimals, symbol, round.currency, round.pricePerToken])

  useEffect(() => {
    const fetchTokenDetails = async () => {
      if (round.currency === NATIVE_TOKEN) return

      const erc20Contract = ERC20.connect(round.currency, provider)
      const decimalsValue = await erc20Contract.decimals()
      const symbolValue = await erc20Contract.symbol()
      setDecimals(decimalsValue)
      setSymbol(symbolValue)
    }

    fetchTokenDetails()
  }, [round, provider])

  return (
    <Row
      className={clsx(
        'mobile:pl-0 pl-4 burn-round-item',
        isLive ? 'bg-[rgba(15,219,209,0.12)]' : 'bg-white/[0.04]',
      )}
      align="top"
      gutter={[0, { lg: 8, md: 12, sm: 12, xs: 12 }]}
    >
      <Col lg={12} md={24} sm={24} xs={24} className="py-4">
        <div className="pb-4 mobile:pb-1 mobile:pl-4 h-10">
          {isLive && (
            <div className="inline-flex items-center px-3 py-1.5 gap-1.5 rounded-[3px] bg-white/[0.06]">
              <LiveIcon />
              <Typography.Text className="text-[#C7FF00] leading-none text-[13px]">
                LIVE
              </Typography.Text>
            </div>
          )}
        </div>

        <Flex vertical className="uppercase gap-3 mobile:pl-4">
          <div className="h-4">
            <Typography.Text
              className="text-[14px] font-semibold"
              type="success"
            >
              {title}
            </Typography.Text>
            <Typography.Text className="text-white/40 text-[14px]">
              {' '}
              |{' '}
            </Typography.Text>
            {eligible ? (
              <Typography.Text className="text-warning text-[14px]">
                Eligible
              </Typography.Text>
            ) : (
              <Typography.Text className="text-[#626576] text-[14px]">
                Not Eligible
              </Typography.Text>
            )}
          </div>

          <Flex align="center" gap={8}>
            {roundWhitelist && roundWhitelist.length > 0 && (
              <Fragment>
                <Typography.Text className="block text-white/90 text-[16px] font-bold">
                  {amount} per wallet
                </Typography.Text>

                <div className="w-[5px] h-[5px] bg-primary rotate-45"></div>
              </Fragment>
            )}

            <Typography.Text className="block text-white/90 text-[16px] font-bold leading-none">
              {round.pricePerToken.eq(0) ? 'Free mint' : formattedPricePerToken}
            </Typography.Text>
          </Flex>
        </Flex>
      </Col>

      <Col lg={12} md={24} sm={24} xs={24}>
        <Flex
          align="center"
          justify="flex-end"
          className={clsx(
            'mobile:justify-center rounded-bl-xl mobile:rounded-none px-4 w-full h-8',
            isLive ? 'bg-black/40' : 'bg-white/[0.06]',
          )}
        >
          <Countdown endedAt={endedDate} startedAt={startedDate} vertical />
        </Flex>
      </Col>
    </Row>
  )
}

export default BurnRoundItem
