import { useContext, useEffect, useMemo, useRef } from 'react'
import BigNumber from 'bignumber.js'

import { Carousel, Image, Skeleton } from 'antd'
import { CarouselRef } from 'antd/es/carousel'

import { RevealNftContext } from '../RevealNft.context'
import ArrowButton from '../arrow-button'

import { useNftImage } from '@/hooks/elite/useNftImage'

import EmptyImg from '@/assets/images/home/empty-elite-nft.png'

import './index.scss'

export default function CarouselThumbnail() {
  const carouselRef = useRef<CarouselRef>(null)
  const { tokenIds, unrevealedTokenIds, recentlyRevealedTokenId } =
    useContext(RevealNftContext)
  const revealedTokenIds = useMemo(() => {
    return tokenIds.filter(
      (tokenId) =>
        !unrevealedTokenIds.find((unrevealedTokenId) =>
          unrevealedTokenId.eq(tokenId),
        ),
    )
    // eslint-disable-next-line
  }, [unrevealedTokenIds])

  useEffect(() => {
    if (!carouselRef.current) return
    if (!recentlyRevealedTokenId) return

    const index = revealedTokenIds.findIndex((tokenId) =>
      tokenId.eq(recentlyRevealedTokenId),
    )
    if (index === -1) return

    carouselRef.current.goTo(index + 1)
  }, [revealedTokenIds, recentlyRevealedTokenId])

  if (!revealedTokenIds.length) {
    return (
      <div>
        <Image
          src={EmptyImg.src}
          alt="elite-nft"
          preview={false}
          className="elite-nft-image"
        />
      </div>
    )
  }

  return (
    <Carousel
      arrows
      infinite={false}
      dots={false}
      prevArrow={<ArrowButton isPrev />}
      nextArrow={<ArrowButton />}
      className="carousel-thumbnail"
      ref={carouselRef}
    >
      {revealedTokenIds.map((tokenId) => (
        <Card tokenId={tokenId} key={tokenId.str()} />
      ))}
    </Carousel>
  )
}

type CardProps = {
  tokenId: BigNumber
}
const Card = ({ tokenId }: CardProps) => {
  const { data: imageUrl, loading } = useNftImage(tokenId)

  if (loading) {
    return (
      <div className="w-full h-full">
        <Skeleton.Image active className="!w-full !h-auto aspect-square" />
      </div>
    )
  }

  return (
    <div>
      <Image
        src={imageUrl}
        alt="elite-nft"
        preview={false}
        className="elite-nft-image"
      />
    </div>
  )
}
