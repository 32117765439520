import { useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import clsx from 'clsx'

import { Flex, Typography } from 'antd'

import CsrProvider from '@/providers/csr.provider'

import { convertTwoDigits } from '@/helpers'

type CountdownProps = {
  startedAt: Dayjs
  endedAt: Dayjs
  vertical?: boolean
}

type CountdownItemProps = {
  label: string
  value: number
  className?: string
}

function CountdownItem({ label, value, className }: CountdownItemProps) {
  return (
    <Flex justify="center" align="center" className="pt-0.5  countdown-item">
      <Typography.Text
        className={clsx('font-bold font-expanded text-[13px]', className)}
      >
        {convertTwoDigits(value)}
        {label}
      </Typography.Text>
    </Flex>
  )
}

function Countdown({ startedAt, endedAt }: CountdownProps) {
  const [currentTime, setCurrentTime] = useState(dayjs())

  const { days, hours, minutes, seconds, isEnded, isLive } = useMemo(() => {
    const isLive =
      currentTime.isAfter(startedAt) && currentTime.isBefore(endedAt)
    const targetTime = isLive ? endedAt : startedAt
    const isEnded = dayjs(targetTime).isBefore(currentTime)

    if (isEnded)
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        isEnded,
      }
    const time = dayjs(targetTime).diff(currentTime)
    const days = Math.floor(time / (1000 * 60 * 60 * 24))
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((time / 1000 / 60) % 60)
    const seconds = Math.floor((time / 1000) % 60)

    return { days, hours, minutes, seconds, isEnded, isLive }
  }, [endedAt, startedAt, currentTime])

  const timeClassName = isLive ? 'text-primary' : 'text-white'

  useEffect(() => {
    const timerId = setInterval(() => setCurrentTime(dayjs()), 1000)

    return () => {
      clearInterval(timerId)
    }
  }, [setCurrentTime])

  if (isEnded)
    return (
      <Flex align="center" justify="center" className="w-full">
        <Typography.Text className="text-[#A3A5AE]">ENDED</Typography.Text>
      </Flex>
    )

  return (
    <CsrProvider>
      <Flex align="center" justify="center" gap={12} className="w-full">
        <Typography.Text className="countdown-title text-[#A3A5AE]">
          {isLive ? 'ENDS IN' : 'STARTS IN'}
        </Typography.Text>

        <Flex gap={8} flex={1} justify="flex-end">
          <CountdownItem className={timeClassName} label="D" value={days} />
          <CountdownItem className={timeClassName} label="H" value={hours} />
          <CountdownItem className={timeClassName} label="M" value={minutes} />
          <CountdownItem className={timeClassName} label="S" value={seconds} />
        </Flex>
      </Flex>
    </CsrProvider>
  )
}

export default Countdown
