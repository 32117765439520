import { useEffect, useMemo, useState } from 'react'
import { ERC20 } from '@space3/web3'
import BigNumber from 'bignumber.js'

import { Button, Flex, Typography } from 'antd'

import { useEthersProvider } from '@/hooks/ether/useEthersProvider'

import { NATIVE_TOKEN } from '@/constants'

import { Round } from '@/types/regen-721-engine.type'

import './index.scss'

type BurnAmountAndPriceProps = {
  activeRound?: Round
  max: BigNumber
  selectedAmount: number
  setSelectedAmount: (amount: number) => void
}
const BurnAmountAndPrice = ({
  activeRound,
  max,
  selectedAmount,
  setSelectedAmount,
}: BurnAmountAndPriceProps) => {
  const provider = useEthersProvider()
  const [decimals, setDecimals] = useState<bigint | null>(null)

  const formattedPricePerToken = useMemo(() => {
    if (!activeRound) return '--'

    if (activeRound.pricePerToken.eq(0))
      return <strong className="text-white">FREE</strong>

    if (activeRound.currency === NATIVE_TOKEN) {
      return activeRound.pricePerToken
        .multipliedBy(selectedAmount)
        .div(Math.pow(10, 18))
        .toNumber()
    }

    return activeRound.pricePerToken
      .multipliedBy(selectedAmount)
      .div(Math.pow(10, Number(decimals)))
      .toNumber()
  }, [activeRound, decimals, selectedAmount])

  useEffect(() => {
    const fetchDecimals = async () => {
      if (!activeRound) return
      if (activeRound.currency === NATIVE_TOKEN) return

      const erc20Contract = ERC20.connect(activeRound.currency, provider)
      const decimalsValue = await erc20Contract.decimals()
      setDecimals(decimalsValue)
    }

    fetchDecimals()
  }, [activeRound, provider])

  return (
    <Flex justify="space-between">
      <Typography.Text className="text-[#4E5161] text-xl">
        Price {formattedPricePerToken}
      </Typography.Text>
      <Flex className="gap-x-3" align="center">
        <Button
          className="btn-amount w-9 h-9 flex justify-center items-center border-0"
          onClick={() => setSelectedAmount(Math.max(0, selectedAmount - 1))}
        >
          -
        </Button>
        <Typography.Text className="text-lg font-semibold min-w-12 text-center">
          {selectedAmount}/{max.str()}
        </Typography.Text>
        <Button
          className="btn-amount w-9 h-9 flex justify-center items-center border-0"
          onClick={() =>
            setSelectedAmount(Math.min(max.toNumber(), selectedAmount + 1))
          }
        >
          +
        </Button>
        <Button
          className="ml-1 border-0 shadow-none bg-[unset] hover:bg-[unset]"
          onClick={() => setSelectedAmount(max.toNumber())}
        >
          <Typography.Text className="hover:text-primary text-[16px] font-medium">
            Max
          </Typography.Text>
        </Button>
      </Flex>
    </Flex>
  )
}

export default BurnAmountAndPrice
