import BigNumber from 'bignumber.js'
import { useQuery } from '@tanstack/react-query'

import { useReadRegen721EngineContract } from './useReadRegen721EngineContract'

import { QUERY_KEY } from '@/constants'

export const useTotalBurnedByUserAddress = (
  seasonId: BigNumber,
  roundIndex: BigNumber,
  userAddress: string,
) => {
  const { contract } = useReadRegen721EngineContract()
  return useQuery({
    queryKey: [
      QUERY_KEY.GET_TOTAL_BURNED_BY_USER_ADDRESS,
      seasonId,
      roundIndex,
      userAddress,
    ],
    queryFn: async () => {
      return BigNumber(
        (
          await contract.claimedAmounts(
            BigInt(seasonId.str()),
            BigInt(roundIndex.str()),
            userAddress,
          )
        ).toString(),
      )
    },
    enabled: !!userAddress,
    initialData: BigNumber(0),
  })
}
