import { useQuery } from '@tanstack/react-query'

import { useReadGenesisContract } from './useReadGenesisContract'

import { QUERY_KEY } from '@/constants'

export const useBalanceOfAddress = (walletAddress: string) => {
  const { contract } = useReadGenesisContract()

  return useQuery({
    queryKey: [QUERY_KEY.GET_BALANCE_OF_ADDRESS, walletAddress],
    queryFn: () => contract!.balanceOf(walletAddress),
    enabled: !!walletAddress && !!contract,
    initialData: BigInt(0),
  })
}
