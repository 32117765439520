import { ConnectButton } from '@rainbow-me/rainbowkit'
import clsx from 'clsx'
import { useCallback } from 'react'
import { useAccount } from 'wagmi'

import { Button, type ButtonProps } from 'antd'
import Icon from '@ant-design/icons'

import CsrProvider from '@/providers/csr.provider'
import ButtonIcon from '@/components/base-btn/ButtonIcon'

import { LogoutIcon } from '@/assets/images/icon'

import './index.scss'

type ConnectWalletBtnProps = {
  clipPath?: boolean
} & Omit<ButtonProps, 'onClick'>

function ConnectWalletBtn({ clipPath, ...rest }: ConnectWalletBtnProps) {
  const { connector } = useAccount()

  const handleDisconnect = useCallback(async () => {
    connector && (await connector.disconnect())
  }, [connector])

  return (
    <CsrProvider>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          const ready = mounted && authenticationStatus !== 'loading'
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === 'authenticated')

          if (!connected)
            return (
              <Button
                onClick={openConnectModal}
                className={clsx(
                  'font-bold h-[44px]',
                  clipPath && 'btn-clip-path',
                )}
                type="primary"
                {...rest}
              >
                Connect Wallet
              </Button>
            )

          if (chain.unsupported) {
            return (
              <Button
                onClick={openChainModal}
                className={clsx(
                  'font-bold h-[44px]',
                  clipPath && 'btn-clip-path',
                )}
                type="primary"
                {...rest}
              >
                Switch Network
              </Button>
            )
          }

          return (
            <div className="connect-wallet-btn">
              <Button
                onClick={openAccountModal}
                className={clsx(
                  'gap-0 font-bold h-[44px]',
                  clipPath && 'btn-clip-path',
                )}
                type="primary"
                {...rest}
              >
                {account.displayName}

                <ButtonIcon
                  onClick={() => handleDisconnect()}
                  icon={<Icon component={LogoutIcon} />}
                />
              </Button>
            </div>
          )
        }}
      </ConnectButton.Custom>
    </CsrProvider>
  )
}

export default ConnectWalletBtn
