import BigNumber from 'bignumber.js'
import { useQuery } from '@tanstack/react-query'

import { useReadRegen721EngineContract } from './useReadRegen721EngineContract'

import { transformSeasonRound } from '@/helpers/transform'

import { QUERY_KEY } from '@/constants'

import { Round } from '@/types/regen-721-engine.type'

export const useAllSeasonRounds = (seasonId: BigNumber) => {
  const { contract } = useReadRegen721EngineContract()

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEY.GET_SEASON_ROUNDS, seasonId],
    queryFn: async () => {
      let roundIndex = BigNumber(0)
      const rounds: Round[] = []
      try {
        // eslint-disable-next-line
        while (true) {
          const round = await contract.seasonRounds(
            BigInt(seasonId.toFixed(0)),
            BigInt(roundIndex.toFixed(0)),
          )
          rounds.push(transformSeasonRound(round))
          roundIndex = roundIndex.plus(1)
        }
      } catch (_) {
        // the above method will throw error when roundIndex out of range
      }
      return rounds
    },
    enabled: !!seasonId,
  })

  return {
    data: data ?? [],
    ...rest,
  }
}
