import { toUtf8Bytes, ContractTransactionResponse } from 'ethers'
import { useMutation } from '@tanstack/react-query'

import { useWriteEliteContract } from './useWriteEliteContract'

import { MUTATION_KEY } from '@/constants'

import { RevealMetadataParams } from '@/types/elite.type'

export const useRevealMetadata = () => {
  const { contract } = useWriteEliteContract()
  return useMutation({
    mutationKey: [MUTATION_KEY.REVEAL_METADATA],
    mutationFn: async ({
      tokenId,
      encryptionKey,
    }: RevealMetadataParams): Promise<
      ContractTransactionResponse | undefined
    > => {
      if (!contract) return
      return contract.reveal(tokenId.bigInt(), toUtf8Bytes(encryptionKey))
    },
  })
}
