import { useContext, useState } from 'react'
import BigNumber from 'bignumber.js'

import { Button, Col, Row, Space, Typography } from 'antd'

import { notify } from '@/providers/notify.provider'
import { RevealNftContext } from '../burn-nft/RevealNft.context'

import { useRevealMetadata } from '@/hooks/elite/useRevealMetadata'

import { nftService } from '@/services/nft'

import { QuestionMarkIcon } from '@/assets/images/icon'

type UnrevealedCardProps = {
  tokenId: BigNumber
}
const UnrevealedCard = ({ tokenId }: UnrevealedCardProps) => {
  const { nftId, setOpenRevealPopUp, setRevealingTokenId, setRevealingTx } =
    useContext(RevealNftContext)
  const [revealing, setRevealing] = useState(false)
  const { mutateAsync: reveal } = useRevealMetadata()

  const revealNft = async () => {
    if (!nftId) {
      notify.error({
        message: 'NFT not found',
      })
      return
    }
    setRevealing(true)
    try {
      const encryptionKey = await nftService.getEncryptionKey({
        nftId,
        tokenId: tokenId.toNumber(),
      })
      const tx = await reveal({
        tokenId,
        encryptionKey,
      })
      if (!tx) return

      setOpenRevealPopUp(false)
      setRevealingTokenId(tokenId)
      setRevealingTx(tx)
    } catch (error: any) {
      notify.error({
        message:
          error?.shortMessage ?? error.message ?? 'Something went wrong!',
      })
    } finally {
      setRevealing(false)
    }
  }

  return (
    <div className="clip-path w-60 h-60  px-6 flex items-center justify-center relative before:bg-[linear-gradient(0deg,rgba(7,81,81,0.90)_-28.48%,rgba(6,63,63,0.00)_100.02%)] before:content-[''] before:absolute before:z-[-1] before:inset-0 before:backdrop-blur-xl before:opacity-50">
      <Row className="flex justify-center items-center" gutter={[0, 24]}>
        <Col span={24}>
          <Space
            className="w-full"
            direction="vertical"
            size={12}
            align="center"
          >
            <Typography.Text className="text-[16px] font-bold font-expanded">
              SPACE3 ELITE NFT
            </Typography.Text>
            <Typography.Text
              type="secondary"
              className="text-sm text-[#A3A5AE]"
            >
              TokenID
              <Typography.Text> {tokenId.str()}</Typography.Text>
            </Typography.Text>
          </Space>
        </Col>
        <Col>
          <QuestionMarkIcon />
        </Col>
        <Col span={24}>
          <Button
            className="btn-clip-path w-full h-11 "
            type="primary"
            onClick={revealNft}
            loading={revealing}
            disabled={!!revealing}
          >
            <Typography.Text className="text-black font-bold">
              REVEAL
            </Typography.Text>
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default UnrevealedCard
