import { useMemo } from 'react'
import { Regen721Engine } from '@space3/web3'

import { useEthersProvider } from '../ether/useEthersProvider'

export const useReadRegen721EngineContract = () => {
  const provider = useEthersProvider()

  const contract = useMemo(
    () =>
      Regen721Engine.connect(
        process.env.NEXT_PUBLIC_REGEN_721_CONTRACT_ADDRESS as string,
        provider,
      ),
    [provider],
  )

  return {
    contract,
  }
}
