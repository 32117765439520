import clsx from 'clsx'

import { Button, ButtonProps } from 'antd'

type ButtonIconProps = {
  wrapperCls?: string
} & Omit<ButtonProps, 'className'>

function ButtonIcon({ wrapperCls = '', ...rest }: ButtonIconProps) {
  return (
    <Button
      type="text"
      className={clsx('!bg-transparent', wrapperCls)}
      {...rest}
    />
  )
}

export default ButtonIcon
