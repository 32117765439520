import BigNumber from 'bignumber.js'

import { useNftImage } from '@/hooks/elite/useNftImage'

import { Image, Skeleton } from 'antd'

import './index.scss'

type RevealedCardProps = {
  tokenId: BigNumber
}

const RevealedCard = ({ tokenId }: RevealedCardProps) => {
  const { data: imageUrl, loading } = useNftImage(tokenId)

  if (loading) {
    return (
      <div className="revealed-card">
        <Skeleton.Image active className="!w-full !h-auto aspect-square" />
      </div>
    )
  }

  return (
    <div className="revealed-card">
      <Image
        src={imageUrl ?? 'https://placehold.co/600x400'}
        preview={false}
        className="object-cover"
        width="100%"
        height="100%"
        alt="nft-thumbnail"
      />
    </div>
  )
}

export default RevealedCard
