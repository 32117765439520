import clsx from 'clsx'
import { ReactNode } from 'react'

type SecondaryButtonProps = {
  className?: string
  children?: ReactNode
  onClick?: () => void
}

export default function SecondaryButton({
  className,
  children,
  onClick,
}: SecondaryButtonProps) {
  return (
    <div className="relative group hover:cursor-pointer" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="227"
        height="36"
        viewBox="0 0 227 36"
        fill="none"
        className={clsx(className, 'group-hover:!fill-[rgba(15,219,209,0.12)]')}
      >
        <g filter="url(#filter0_i_30750_11497)">
          <path
            d="M214.342 36H2.5715C1.15134 36 7.46001e-05 34.8487 7.01578e-05 33.4286L3.53839e-06 12.1312C0 11.4104 0.302521 10.7227 0.833854 10.2356L11.2627 0.675888C11.7369 0.241161 12.3569 0 13.0002 0H150.833H224.429C225.849 0 227 1.15127 227 2.57143V22.0259C227 22.6535 226.771 23.2595 226.355 23.7296L216.268 35.1323C215.779 35.6841 215.078 36 214.342 36Z"
            fill="#0FDBD1"
            fillOpacity="0.12"
          />
        </g>
        <path
          d="M2.5715 35.8393H214.342C215.032 35.8393 215.69 35.5431 216.147 35.0259L226.234 23.6231C226.624 23.1824 226.839 22.6143 226.839 22.0259V2.57143C226.839 1.24003 225.76 0.160713 224.429 0.160713H150.833H13.0002C12.3971 0.160713 11.8159 0.386803 11.3713 0.794361L0.942453 10.3541C0.444328 10.8107 0.160715 11.4554 0.160718 12.1312L0.160784 33.4286C0.160789 34.76 1.2401 35.8393 2.5715 35.8393Z"
          stroke="#0FDBD1"
          strokeWidth="0.321429"
        />
        <defs>
          <filter
            id="filter0_i_30750_11497"
            x="0"
            y="0"
            width="227"
            height="36"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0588235 0 0 0 0 0.858824 0 0 0 0 0.819608 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_30750_11497"
            />
          </filter>
        </defs>
      </svg>
      {!!children && <div className="absolute inset-0">{children}</div>}
    </div>
  )
}
