import BigNumber from 'bignumber.js'

// These types were get from contract
type RoundStruct = {
  cumulativeMaxSupply: bigint
  startTime: bigint
  endTime: bigint
  pricePerToken: bigint
  currency: string
  merkleRoot: string
}

export type SeasonStruct = {
  maxSupply: bigint
  totalDistributed: bigint
}

export const transformSeasonRound = (round: RoundStruct) => {
  const {
    cumulativeMaxSupply,
    startTime,
    endTime,
    pricePerToken,
    currency,
    merkleRoot,
  } = round

  return {
    cumulativeMaxSupply: BigNumber(cumulativeMaxSupply.toString()),
    startTime: BigNumber(startTime.toString()),
    endTime: BigNumber(endTime.toString()),
    pricePerToken: BigNumber(pricePerToken.toString()),
    currency,
    merkleRoot,
  }
}

export const transformSeason = (season: SeasonStruct) => {
  const { maxSupply, totalDistributed } = season

  return {
    maxSupply: BigNumber(maxSupply.toString()),
    totalDistributed: BigNumber(totalDistributed.toString()),
  }
}
