import { useMemo } from 'react'
import { useAccount } from 'wagmi'

import { Button, Tooltip } from 'antd'

import ConnectWalletBtn from '@/components/connect-wallet-btn'

type BurnBtnProps = {
  loading: boolean
  disable: boolean
  tooltip?: string
  onBurn: () => void
}

function BurnBtn({ onBurn, loading, disable, tooltip }: BurnBtnProps) {
  const { isConnected, address, chain } = useAccount()

  const showConnect = useMemo(
    () => !isConnected || !address || !chain,
    [address, chain, isConnected],
  )

  if (showConnect) return <ConnectWalletBtn clipPath block />

  if (tooltip)
    return (
      <Tooltip title={tooltip}>
        <Button
          className="font-bold h-[44px] btn-clip-path"
          block
          type="primary"
          onClick={onBurn}
          loading={loading}
          disabled={disable}
        >
          BURN
        </Button>
      </Tooltip>
    )

  return (
    <Button
      className="font-bold h-[44px] btn-clip-path"
      block
      type="primary"
      onClick={onBurn}
      loading={loading}
      disabled={disable}
    >
      BURN
    </Button>
  )
}

export default BurnBtn
