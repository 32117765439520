import { useMemo } from 'react'
import { Space3EliteSoul } from '@space3/web3'

import { useEthersProvider } from '../ether/useEthersProvider'
import { useCurrentSeason } from '../system/useCurrentSeason'
import { useSeasonSetting } from '../regen721/useSeasonSetting'

export const useReadEliteContract = () => {
  const provider = useEthersProvider()

  const seasonId = useCurrentSeason()
  const { data } = useSeasonSetting(seasonId!)

  const contract = useMemo(() => {
    if (!data) return null
    return Space3EliteSoul.connect(data.mintToken, provider)
  }, [provider, data])

  return {
    contract,
    contractAddress: data?.mintToken,
  }
}
