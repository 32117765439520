import BigNumber from 'bignumber.js'
import { useQuery } from '@tanstack/react-query'

import { awsS3Service } from '@/services/aws-s3'

import { QUERY_KEY } from '@/constants'
import { ONE_MINUTE } from '@/constants/date-time.constant'

export const useRoundWhitelist = (
  seasonId: BigNumber,
  roundIndex: BigNumber,
) => {
  return useQuery({
    queryKey: [QUERY_KEY.GET_ROUND_WHITELIST, seasonId, roundIndex],
    queryFn: () =>
      awsS3Service.getRoundWhitelist(seasonId.str(), roundIndex.str()),
    staleTime: 10 * ONE_MINUTE,
    enabled: !!seasonId && roundIndex.gte(0),
  })
}
