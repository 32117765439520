import { useMutation } from '@tanstack/react-query'

import { useWriteRegen721EngineContract } from './useWriteRegen721EngineContract'

import { MUTATION_KEY } from '@/constants'

import { BurnNftParams } from '@/types/regen-721-engine.type'

export const useBurnTokens = () => {
  const { contract } = useWriteRegen721EngineContract()
  return useMutation({
    mutationKey: [MUTATION_KEY.BURN_NFTS],
    mutationFn: ({
      seasonId,
      roundIndex,
      total,
      burnTokenIds,
      proofs,
      price,
    }: BurnNftParams) => {
      return contract.regen(
        seasonId.bigInt(),
        roundIndex.bigInt(),
        total.bigInt(),
        burnTokenIds.map((tokenId) => tokenId.bigInt()),
        proofs,
        {
          value: price.bigInt(),
        },
      )
    },
  })
}
