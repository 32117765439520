import { useQuery } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'

import { useReadRegen721EngineContract } from './useReadRegen721EngineContract'

import { transformSeason } from '@/helpers/transform'

import { QUERY_KEY } from '@/constants'
import { ONE_MINUTE } from '@/constants/date-time.constant'

export const useSeason = (seasonId: BigNumber) => {
  const { contract } = useReadRegen721EngineContract()

  return useQuery({
    queryKey: [QUERY_KEY.GET_SEASON, seasonId],
    queryFn: async () => {
      const season = await contract.seasons(BigInt(seasonId.toFixed(0)))

      return transformSeason(season)
    },
    staleTime: 10 * ONE_MINUTE,
    enabled: !!seasonId,
  })
}
