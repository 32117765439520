export const shortenAddress2Direction = (
  address: string,
  before = 4,
  after = 4,
  delimiter = '...',
) => {
  if (!address) return ''

  return (
    address.substring(0, before) +
    delimiter +
    address.substring(address.length - after, address.length)
  )
}

export const convertTwoDigits = (value: number | undefined) => {
  if (!value && value !== 0) return ''

  if (value < 10) return `0${value}`
  return value
}

export const insensitiveEquals = (addressA: string, addressB: string) => {
  return addressA.toLowerCase() === addressB.toLowerCase()
}
