import BigNumber from 'bignumber.js'
import { useQuery } from '@tanstack/react-query'

import { useReadGenesisContract } from './useReadGenesisContract'

import { QUERY_KEY } from '@/constants'

export const useTokenIdsOwnedByAddress = (userAddress: string) => {
  const { contract, contractAddress } = useReadGenesisContract()

  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_KEY.GET_TOKEN_IDS_OWNED_BY_ADDRESS,
      contractAddress,
      userAddress,
    ],
    queryFn: async () => {
      if (!contract) return []

      const tokenLength = await contract.balanceOf(userAddress)
      const encodedMulticallData = []
      for (let i = 0; BigNumber(tokenLength.toString()).gt(i); i++) {
        encodedMulticallData.push(
          contract.interface.encodeFunctionData('tokenOfOwnerByIndex', [
            userAddress,
            i,
          ]),
        )
      }

      const result = await contract.multicall.staticCall(encodedMulticallData)
      return result.map((data) =>
        BigNumber(
          (
            contract.interface.decodeFunctionResult(
              'tokenOfOwnerByIndex',
              data,
            )[0] as bigint
          ).toString(),
        ),
      )
    },
    enabled: !!userAddress && !!contract,
    initialData: [],
  })

  return {
    data,
    ...rest,
  }
}
