import { useMemo } from 'react'
import { Space3GenesisSoul } from '@space3/web3'

import { useEthersSigner } from '../ether/useEthersSigner'
import { useCurrentSeason } from '../system/useCurrentSeason'
import { useSeasonSetting } from '../regen721/useSeasonSetting'

export const useWriteGenesisContract = () => {
  const signer = useEthersSigner()
  const seasonId = useCurrentSeason()
  const { data } = useSeasonSetting(seasonId!)

  const contract = useMemo(() => {
    if (!data) return null
    return Space3GenesisSoul.connect(data.burnToken, signer)
  }, [signer, data])

  return {
    contract,
  }
}
