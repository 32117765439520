import { useMutation } from '@tanstack/react-query'
import { ContractTransactionResponse } from 'ethers'

import { useWriteGenesisContract } from './useWriteGenesisContract'

import { MUTATION_KEY } from '@/constants'

import { Approve721ForAllParams } from '@/types/genesis.type'

export const useSetApprovedForAll = () => {
  const { contract } = useWriteGenesisContract()
  return useMutation({
    mutationKey: [MUTATION_KEY.APPROVE_721_FOR_ALL],
    mutationFn: async ({
      spender,
      isApproved = true,
    }: Approve721ForAllParams): Promise<
      ContractTransactionResponse | undefined
    > => {
      if (!contract) return
      return contract.setApprovalForAll(spender, isApproved)
    },
  })
}
