import { Button, Pagination } from 'antd'
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'

import useIsMobile from '@/hooks/system/useIsMobile'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/query.constant'

import './index.scss'

export type CustomPaginationProps = {
  pageSize?: number
  onChange?: (page: number) => void
  total?: number
  page?: number
}

const CustomPagination = ({
  pageSize = DEFAULT_PAGE_SIZE,
  page = DEFAULT_PAGE,
  onChange = () => {},
  total = 0,
}: CustomPaginationProps) => {
  const lastPage = Math.ceil(total / pageSize)
  const isMobile = useIsMobile()

  return (
    <div className="pagination-container">
      {!isMobile && (
        <Button
          size="small"
          className="btn-pagination"
          onClick={() => onChange(DEFAULT_PAGE)}
          disabled={page === 1}
          icon={<DoubleLeftOutlined />}
        />
      )}

      <Pagination
        total={total}
        defaultPageSize={pageSize}
        showSizeChanger={false}
        current={page}
        onChange={onChange}
        showLessItems={true}
        showTitle={false}
        responsive={true}
      />

      {!isMobile && (
        <Button
          size="small"
          onClick={() => onChange(lastPage)}
          className="btn-pagination"
          disabled={page === lastPage}
          icon={<DoubleRightOutlined />}
        />
      )}
    </div>
  )
}

export default CustomPagination
