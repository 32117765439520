import { useState } from 'react'

import { Button, Col, Row, Tooltip, Typography } from 'antd'

import RevealButton from '../reveal-button'

import { DoubleRightOutlined, ExclamationCircleFilled } from '@ant-design/icons'

import './index.scss'

type BurnRuleProps = {
  desc: string
}

function BurnRule({ desc }: BurnRuleProps) {
  const [showMore, setShowMore] = useState(false)
  const handleSeeMore = () => {
    setShowMore(true)
  }
  const handleSeeLess = () => {
    setShowMore(false)
  }

  return (
    <Row
      gutter={[12, { xs: 12, sm: 12, lg: 20 }]}
      className="burn-rule-container"
    >
      <Col lg={14} sm={9} xs={9} className="rule-item flex items-center">
        <Typography.Text className="rule-item--title !mr-[4px]">
          BURN RULE
        </Typography.Text>
        <Tooltip
          title="Burn 1x Space3 Genesis NFT to Redeem 1x Space3 Elite NFT"
          rootClassName="rule-item--tooltip"
        >
          <ExclamationCircleFilled />
        </Tooltip>
      </Col>
      <Col lg={10} sm={15} xs={15}>
        <RevealButton />
      </Col>
      <Col span={24}>
        <Typography.Paragraph
          className="rule-item--desc"
          ellipsis={
            showMore
              ? undefined
              : {
                  rows: 2,
                  expandable: true,
                  symbol: (
                    <Button
                      onClick={handleSeeMore}
                      className="border-none shadow-none px-0 ml-[-4px] !h-auto py-0"
                    >
                      <div className="rotate-90">
                        <DoubleRightOutlined size={12} />
                      </div>
                    </Button>
                  ),
                }
          }
          key={showMore ? 1 : 0}
        >
          {desc}{' '}
          {showMore && (
            <Button
              onClick={handleSeeLess}
              className="border-none shadow-none px-0 !h-auto py-0"
            >
              <div className="rotate-[-90deg]">
                <DoubleRightOutlined size={12} />
              </div>
            </Button>
          )}
        </Typography.Paragraph>
      </Col>
    </Row>
  )
}

export default BurnRule
