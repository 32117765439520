import BigNumber from 'bignumber.js'
import { useQuery } from '@tanstack/react-query'

import { useReadEliteContract } from './useReadEliteContract'

import { QUERY_KEY } from '@/constants'

export const useUnrevealedTokenIds = (tokenIds: BigNumber[]) => {
  const { contract } = useReadEliteContract()
  return useQuery({
    queryKey: [
      QUERY_KEY.GET_UNREVEALED_TOKEN_IDS,
      tokenIds.map((tokenId) => tokenId.toNumber()).sort(),
    ],
    queryFn: async () => {
      if (!contract) return []

      const encodedMulticallData: string[] = []
      for (let i = 0; i < tokenIds.length; i++) {
        encodedMulticallData.push(
          contract.interface.encodeFunctionData('isEncrypted', [
            tokenIds[i].bigInt(),
          ]),
        )
      }

      const result = await contract.multicall.staticCall(encodedMulticallData)
      const unrevealedList = []
      for (let i = 0; i < result.length; i++) {
        const isEncrypted = contract.interface.decodeFunctionResult(
          'isEncrypted',
          result[i],
        )[0] as boolean

        if (isEncrypted) {
          unrevealedList.push(tokenIds[i])
        }
      }

      return unrevealedList
    },
    initialData: [],
    enabled: !!contract,
  })
}
