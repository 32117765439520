import axios from 'axios'

import configs from '@/configs'

const axiosConfig = {
  timeout: 30000,
  baseURL: configs.api.nftApi,
  paramsSerializer: {
    indexes: null,
  },
}

export const nftApi = axios.create(axiosConfig)
