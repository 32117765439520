import { Fragment, useContext, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'

import { Col, Modal, Row, Typography } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { RevealNftContext } from '../burn-nft/RevealNft.context'
import UnrevealedCard from './unrevealed-card'
import RevealedCard from './revealed-card'
import CustomPagination from '@/components/pagination/pagination'
import ButtonIcon from '@/components/base-btn/ButtonIcon'

import useIsMobile from '@/hooks/system/useIsMobile'

import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  MOBILE_ELITE_NFT_PAGE_SIZE,
} from '@/constants/query.constant'

import { ExclamationMarkIcon } from '@/assets/images/icon'

import './index.scss'

const RevealPopup = () => {
  const { openRevealPopUp, tokenIds, unrevealedTokenIds, setOpenRevealPopUp } =
    useContext(RevealNftContext)

  const isMobile = useIsMobile()

  const [page, setPage] = useState(DEFAULT_PAGE)
  const startIndex = (page - 1) * DEFAULT_PAGE_SIZE
  const endIndex = startIndex + DEFAULT_PAGE_SIZE
  const paginatedTokenIds = tokenIds.slice(startIndex, endIndex)

  const onChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const paginatedItems = useMemo(() => {
    const startIndex = (page - 1) * MOBILE_ELITE_NFT_PAGE_SIZE
    const endIndex = startIndex + MOBILE_ELITE_NFT_PAGE_SIZE
    return tokenIds.slice(startIndex, endIndex)
  }, [page, tokenIds])

  return (
    <Modal
      open={openRevealPopUp}
      footer={null}
      width={800}
      className={clsx(
        'reveal-popup',
        tokenIds.length <= 3 || isMobile
          ? 'reveal-popup-small'
          : tokenIds.length > 6
            ? 'reveal-popup-large'
            : 'reveal-popup-medium',
      )}
      styles={{
        content: {
          backgroundColor: 'unset',
          boxShadow: 'unset',
          padding: '40px 20px 20px 20px',
        },
      }}
      onCancel={() => setOpenRevealPopUp(false)}
      centered
      destroyOnClose
    >
      <Row gutter={[0, { lg: 24, sm: 12, xs: 12 }]}>
        <Col span={24}>
          <Typography.Text className="title bg-[linear-gradient(90deg,#ECA366_29.83%,#FF7300_69.35%)] bg-clip-text text-[28px] mobile:text-[20px] font-bold block text-center leading-[100%]">
            YOUR ELITE NFTS
          </Typography.Text>
        </Col>

        {!tokenIds.length ? (
          <Col span={24}>
            <EmptyCase />
          </Col>
        ) : (
          <Fragment>
            {!isMobile && (
              <Fragment>
                <Col span={24}>
                  <Row
                    gutter={[20, 20]}
                    justify={paginatedTokenIds.length < 3 ? 'center' : 'start'}
                    wrap={(isMobile && true) || undefined}
                    className={clsx(isMobile && 'overflow-x-auto')}
                  >
                    {paginatedTokenIds.map((tokenId) => (
                      <Col key={tokenId.str()} lg={8} sm={22} xs={22}>
                        <Card
                          tokenId={tokenId}
                          isRevealed={
                            !unrevealedTokenIds.find((unrevealedTokenId) =>
                              unrevealedTokenId.eq(tokenId),
                            )
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>

                {tokenIds.length > 6 && (
                  <Col span={24} className="mb-[20px]">
                    <CustomPagination
                      total={tokenIds.length}
                      onChange={onChangePage}
                      page={page}
                      pageSize={DEFAULT_PAGE_SIZE}
                    />
                  </Col>
                )}
              </Fragment>
            )}

            {isMobile && (
              <Col span={24}>
                <Row
                  gutter={20}
                  justify={paginatedItems.length === 1 ? 'center' : 'start'}
                  wrap={false}
                  className="overflow-x-auto mb-[7px] pb-[7px]"
                >
                  {paginatedItems.map((tokenId) => (
                    <Col key={tokenId.str()}>
                      <Card
                        tokenId={tokenId}
                        isRevealed={
                          !unrevealedTokenIds.find((unrevealedTokenId) =>
                            unrevealedTokenId.eq(tokenId),
                          )
                        }
                      />
                    </Col>
                  ))}
                </Row>

                {tokenIds.length > 4 && (
                  <Row
                    justify="center"
                    align="middle"
                    wrap={false}
                    className="mob-reveal-popup--pagination"
                    gutter={[8, 0]}
                  >
                    <Col>
                      <ButtonIcon
                        wrapperCls="btn-prev"
                        icon={<LeftOutlined />}
                        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                      />
                    </Col>
                    <Col className="fraction-pagination">
                      <Typography.Text className="text-[#F1F2F3]">
                        {page}
                      </Typography.Text>
                      <Typography.Text>/</Typography.Text>
                      <Typography.Text className="text-[#ffffff80]">
                        {Math.ceil(
                          tokenIds.length / MOBILE_ELITE_NFT_PAGE_SIZE,
                        )}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <ButtonIcon
                        wrapperCls="btn-next"
                        icon={<RightOutlined />}
                        onClick={() =>
                          setPage((prev) =>
                            Math.min(
                              prev + 1,
                              Math.ceil(
                                tokenIds.length / MOBILE_ELITE_NFT_PAGE_SIZE,
                              ),
                            ),
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            )}
          </Fragment>
        )}
      </Row>
    </Modal>
  )
}

// eslint-disable-next-line
const EmptyCase = () => (
  <Row gutter={[0, 32]} justify="center" className="h-60">
    <Col className="flex items-end">
      <ExclamationMarkIcon className="opacity-30" />
    </Col>
    <Col span={24}>
      <Typography.Text className="block text-center text-lg text-[#C4C6CD]">
        No Elite NFT found. Burn a Genesis NFT to reveal one.
      </Typography.Text>
    </Col>
  </Row>
)

type CardProps = {
  tokenId: BigNumber
  isRevealed: boolean
}
const Card = ({ tokenId, isRevealed }: CardProps) => {
  if (isRevealed) {
    return <RevealedCard tokenId={tokenId} />
  }

  return <UnrevealedCard tokenId={tokenId} />
}

export default RevealPopup
