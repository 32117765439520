import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import BigNumber from 'bignumber.js'

import { Col, Collapse, Row, Typography } from 'antd'
import Icon, { CaretDownOutlined } from '@ant-design/icons'

import { useSeason } from '@/hooks/regen721/useSeason'

import { DATE_FORMAT } from '@/constants/date-time.constant'

import './index.scss'

type NftDetailsProps = {
  seasonId: BigNumber
  startTime: Dayjs
  endTime: Dayjs
}

function NftDetails({ seasonId, startTime, endTime }: NftDetailsProps) {
  const { data: season } = useSeason(seasonId)

  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <Icon
          component={() => <CaretDownOutlined />}
          alt="burn-arrow-icon"
          className={clsx(
            'burn-collapse-arrow',
            isActive && 'burn-collapse-arrow-active',
          )}
        />
      )}
      expandIconPosition="end"
      items={[
        {
          label: <Typography.Text>VIEW DETAILS</Typography.Text>,
          children: (
            <Typography.Paragraph>
              <Row>
                <Col span={12} className="py-3">
                  <Typography.Text>SUPPLY</Typography.Text>
                </Col>
                <Col span={12} className="py-3 text-end">
                  <Typography.Text>
                    {season?.maxSupply.toString() ?? 0}
                  </Typography.Text>
                </Col>

                <Col lg={12} sm={8} xs={8} className="py-3">
                  <Typography.Text>START DATE</Typography.Text>
                </Col>
                <Col lg={12} sm={16} xs={16} className="py-3 text-end">
                  <Typography.Text>
                    {dayjs(startTime).utc().format(DATE_FORMAT)}
                  </Typography.Text>
                </Col>

                <Col lg={12} sm={8} xs={8} className="py-3">
                  <Typography.Text>END DATE</Typography.Text>
                </Col>
                <Col lg={12} sm={16} xs={16} className="py-3 text-end">
                  <Typography.Text>
                    {dayjs(endTime).utc().format(DATE_FORMAT)}
                  </Typography.Text>
                </Col>

                <Col span={12} className="py-3">
                  <Typography.Text>NETWORK</Typography.Text>
                </Col>
                <Col span={12} className="py-3 text-end">
                  <Typography.Text>ANCIENT8</Typography.Text>
                </Col>
              </Row>
            </Typography.Paragraph>
          ),
        },
      ]}
    />
  )
}

export default NftDetails
