import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import axios from 'axios'

import { useTokenUri } from './useTokenUri'

import { NftMetadata } from '@/types/elite.type'

export const useNftImage = (tokenId: BigNumber) => {
  const [loading, setLoading] = useState(true)
  const [imageUrl, setImageUrl] = useState<string>()
  const { data: uri } = useTokenUri(tokenId)

  const fetchMetadata = useCallback(async () => {
    if (!uri) {
      setLoading(true)
      return
    }

    try {
      const axiosApi = axios.create()
      const { data: metadata } = await axiosApi.get<NftMetadata>(uri)
      setImageUrl(metadata.image)
    } catch (_) {
      // catch to prevent load failed
    } finally {
      setLoading(false)
    }
  }, [uri])

  useEffect(() => {
    setLoading(true)
    fetchMetadata()
  }, [fetchMetadata])
  return {
    data: imageUrl,
    loading,
  }
}
