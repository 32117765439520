import { Col, Image, Row, Typography } from 'antd'

import CarouselThumbnail from '../carousel-thumbnail'

import BrandLogo from '@/assets/images/logo/brand.svg'
import BurnFireGif from '@/assets/images/home/burn-fire.gif'
import ArrowRight from '@/assets/images/icon/ArrowRight.gif'
import BaseNft from '@/assets/images/home/base-nft.png'
import BaseNftEmpty from '@/assets/images/home/base-nft-empty.png'

import './index.scss'

type NftConvertProps = {
  isBaseNftExist?: boolean
}

function NftConvert({ isBaseNftExist = false }: NftConvertProps) {
  return (
    <Row gutter={[12, 12]} align="middle">
      <Col span={10}>
        <div className="base-nft">
          <div className="brand-logo">
            <Image preview={false} src={BrandLogo.src} alt="brand-logo" />
          </div>

          <Image
            src={isBaseNftExist ? BaseNft.src : BaseNftEmpty.src}
            alt="base-nft"
            preview={false}
            className="base-nft-image"
          />

          <div className="burn-fire-item">
            <Image
              src={BurnFireGif.src}
              alt="burn-fire"
              preview={false}
              className="burn-fire-image"
            />
          </div>

          {!isBaseNftExist && (
            <div className="empty-nft-wrap-text">
              <Typography.Text className="empty-nft-text">
                You have no Space3 Genesis NFT in your wallet
              </Typography.Text>
            </div>
          )}
        </div>
      </Col>

      <Col span={4} className="flex justify-center">
        <Image src={ArrowRight.src} alt="arrow-icon" preview={false} />
      </Col>

      <Col span={10} className="elite-nft">
        <CarouselThumbnail />
      </Col>
    </Row>
  )
}

export default NftConvert
